import { StyledUl } from './UnorderedList.styled';
import { UnorderedListProperties } from './UnorderedList.types';

/*
 * Use the `UnorderedList` component to display a list of items with bullet points.
 */
export function UnorderedList({
    children,
    className,
}: UnorderedListProperties) {
    if (!children) {
        return null;
    }

    return <StyledUl className={className}>{children}</StyledUl>;
}

export default UnorderedList;
