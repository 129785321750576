import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { TelephoneInput } from '../../Forms/TelephoneInput';
import { ControlledTelephoneInputProperties } from './ControlledTelephoneInput.types';
import { telephoneValidator } from '@tgg/form-validation';

export function ControlledTelephoneInput({
    control,
    name = 'phoneNumber',
    label = 'Mobile Phone Number',
}: ControlledTelephoneInputProperties) {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: 'Enter a valid phone number',
                validate: {
                    validator: telephoneValidator,
                },
            }}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid, isDirty, error },
            }) => {
                return (
                    <TelephoneInput
                        hasMarginTop
                        label={
                            <>
                                {label}
                                <Asterisk spaceBefore />
                            </>
                        }
                        value={value}
                        errorMessage={error?.message}
                        isInvalid={invalid}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="XXXX XXX XXX"
                        id="phone-number"
                        inputProps={{
                            inputMode: 'tel',
                        }}
                        iconElementRight={{
                            name: !invalid && isDirty ? 'tick' : 'blank',
                        }}
                    />
                );
            }}
        />
    );
}
export default ControlledTelephoneInput;
