import { UseFormGetValues } from 'react-hook-form';

type StayInTouchValidationArguments = {
    getValues: UseFormGetValues<any>;
    emailFieldName: string;
    smsFieldName: string;
};

export const stayInTouchValidation =
    ({
        getValues,
        emailFieldName,
        smsFieldName,
    }: StayInTouchValidationArguments) =>
    (): string | boolean => {
        const emailOptIn = getValues(emailFieldName);
        const smsOptIn = getValues(smsFieldName);

        if (!emailOptIn && !smsOptIn) {
            return false;
        }

        return true;
    };
