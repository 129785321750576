import { Controller } from 'react-hook-form';

import { Tickbox } from '../../Tickbox';
import { ControlledCheckboxInputProperties } from './ControlledCheckboxInput.types';

export function ControlledCheckboxInput({
    control,
    name,
    rules,
    label,
    onChange,
}: ControlledCheckboxInputProperties) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <Tickbox
                    name={name}
                    checked={field.value}
                    onChange={event =>
                        onChange
                            ? onChange(event)
                            : field.onChange(event.target.checked)
                    }
                >
                    {label}
                </Tickbox>
            )}
        />
    );
}
