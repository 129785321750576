import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { TextInput } from '../../Forms/TextInput';
import { StyledLabel } from '../ControlledInputs.styled';
import { ControlledTextInputProperties } from './ControlledTextInput.types';

export function ControlledTextInput({
    control,
    name,
    rules,
    label,
    placeholder,
    disabled,
    isRequired,
    id,
    multiline,
    inputProps,
}: ControlledTextInputProperties) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid, isDirty, error },
            }) => {
                return (
                    <>
                        <StyledLabel
                            $hasPaddingTop
                            data-testid={`${id}-label`}
                            htmlFor={id}
                        >
                            {label}
                            {isRequired && <Asterisk spaceBefore />}
                        </StyledLabel>
                        <TextInput
                            multiline={multiline}
                            value={value}
                            errorMessage={error?.message}
                            isInvalid={invalid}
                            placeholder={placeholder}
                            id={id}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                            iconElementRight={{
                                name: !invalid && isDirty ? 'tick' : 'blank',
                            }}
                            inputProps={inputProps}
                        />
                    </>
                );
            }}
        />
    );
}
