/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';

import { palette } from '../../theme';
import { Icon } from '../Icon';
import {
    StyledWrapper,
    StyledCheckbox,
    StyledLabel,
    StyledErrorMessage,
} from './Tickbox.styled';
import { TickboxProperties } from './Tickbox.types';
import { encodeAsBase64 } from '@tgg/util';

/**
 *  A simple tickbox component with optional label
 */
export const Tickbox = React.forwardRef(
    (
        {
            checked,
            required,
            errorMessage,
            name,
            onChange,
            children,
        }: TickboxProperties,
        reference?: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const [isChecked, setIsChecked] = useState(checked || false);
        useEffect(() => {
            setIsChecked(!!checked);
        }, [checked]);
        const id = encodeAsBase64(name);

        return (
            <StyledWrapper>
                <StyledCheckbox
                    required={required}
                    ref={reference}
                    id={id}
                    checked={isChecked}
                    icon={
                        <Icon
                            name="tick"
                            color={palette.common.white}
                            size={20}
                        />
                    }
                    checkedIcon={
                        <Icon
                            name="tick"
                            color={palette.common.blue}
                            size={20}
                        />
                    }
                    disableRipple
                    onClick={() => {
                        setIsChecked(!isChecked);
                    }}
                    name={name}
                    onChange={onChange}
                />
                <StyledLabel htmlFor={id}>{children}</StyledLabel>
                {errorMessage && (
                    <StyledErrorMessage $error={palette.error}>
                        {errorMessage}
                    </StyledErrorMessage>
                )}
            </StyledWrapper>
        );
    },
);
