import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledWrapper = styled('div')`
    display: flex;
    min-width: 10rem;
    line-height: 2rem;
`;

export const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: baseline;
    padding: 0;
    background-color: ${palette.grey[900]};
    border-radius: 50%;

    &.Mui-checked,
    &.Mui-checked:hover {
        background-color: ${palette.secondary.main};
    }

    &:focus-within {
        outline: 0.1rem solid ${palette.primary.main};
    }

    svg {
        padding: 0.2rem;
    }
`;

export const StyledLabel = styled('label')`
    display: inline-block;
    padding: 0 1rem;
    font-weight: 500;
    cursor: pointer;
`;

export const StyledErrorMessage = styled('div')<{ $error: any }>`
    color: ${({ $error }) => $error.main};
`;
